import en from "./localization/en";
import ru from "./localization/ru";

class Consts {
    private static _language = "en";
    private static _cause = "default";
    private static _product = "era";

    private static setLanguage(language_: string, cause_: string) {
        if (this._language === language_ && this._cause === cause_)
            return;

        this._language = language_;
        this._cause = cause_;

        console.log(`Language ${this._language} set by ${this._cause}`);
        document.title = Consts.appTitle;
    }

    private static setProduct(product_: string) {
        this._product = product_;
        const root = document.getElementById('root');
        if (root) {
            if (this._product === "incoplax" || this._product === "infinity")
                root.className = "eraThemeOrange";
            else
                root.className = "eraThemeBlue";
        }
    }

    public static resetProduct() {
        const root = document.getElementById('root');
        if (root) {
            root.className = "";
        }
    }

    public static get language() {
        return this._language;
    }

    public static get product() {
        return this._product;
    }

    public static initializeByBrowser() {
        if (navigator.language.startsWith("ru"))
            this.setLanguage("ru", "browser");
        else
            this.setLanguage("en", "browser");
    }

    public static async initializeBySession(session_: any) {
        let product = session_ ? session_.product : undefined;

        if (product) {
            this.setProduct(product);
        }
        
        if (product === "era" || product === "infinity") {
            this.setLanguage("ru", "product");
        }
        else if (product === "incoplax") {
            this.setLanguage("en", "product");
        }

        const languageUrl = "/rest/v1/model/platform/settings/Settings/437d33f8-b8a7-cb60-ae19-c477aa2a7d8a";
        try {
            const resp: Response = await fetch(languageUrl, { method: 'GET', headers: { 'Content-Type': 'application/json;charset=utf-8' } });
            let respBody: any = {};
            respBody = await resp.json();

            if (respBody && respBody.value) {
                this.setLanguage(respBody.value, "setting");
            }
        }
        catch {
        };
    }

    static get consts() {
        if (this._language === "ru")
            return ru;
        else
            return en;
    }

    static get appTitle() {
        return this.consts.appTitle;
            
    }
    static get pages() {
        return this.consts.pages;
    }
    static get stores() {
        return this.consts.stores;
    }
}

export default Consts;