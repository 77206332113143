const ru = {
    appTitle: "Приложения",
    pages: {
        appsPage: {
            exit: "Выйти",
            closeOtherSessions: "Закрыть остальные сессии",
            eraConnectorText: "Скачать коннектор"
        },
        inviteRegisterPage: {
            headerSubmit: "Подтверждение регистрации",
            headerInvite: "Регистрация по приглашению",
            domain: "Домен",
            name: "Имя",
            login: "Логин",
            pwd: "Пароль",
            pwdRepeat: "Подтвердите пароль",
            next: "Далее",
            errorDifferentPwd: "Пароли не совпадают"
        },
        loginPage: {
            header: "Авторизация",
            domain: "Домен",
            login: "Логин",
            password: "Пароль",
            buttonLogin: "Войти",
            closeOtherSessions: "предварительно закрыв все сессии",
            linkRestorePwd: "Восстановить пароль",
            linkRegister: "Зарегистрироваться"
        },
        restorePwdPage: {
            header: "Восстановление пароля",
            loginEmail: "Email или логин",
            domainRequired: "Домен",
            domainOptional: "Домен (необязательно)",
            next: "Далее"
        },
        resetPwdPage: {
            header: "Восстановление пароля",
            pwd: "Пароль",
            pwdRepeat: "Подтвердите пароль",
            next: "Далее",
            errorDifferentPwd: "Пароли не совпадают"
        },
        registerPage: {
            header: "Регистрация",
            domain: "Домен",
            name: "Имя",
            login: "Логин",
            next: "Далее"
        }
    },
    stores: {
        dataUtils: {
            successResultTexts: {
                restorePwd: "Ссылка для сброса пароля отправлена на email",
                register: "Ссылка для подтверждения регистрации отправлена на email",
                registerSubmit: "Используйте новый пароль для авторизации",
                inviteSubmit: "Используйте новый пароль для авторизации",
                resetPwd: "Используйте новый пароль для авторизации"
            },
            errorCodesTexts: {
                domainNotFound: "Домен не найден",
                userNotFound: "Пользователь не найден",
                invalidData: {
                    invalidEmail: "Некорректный email",
                    invalidPwd: "Некорректный пароль"
                },
                accessDenied: "Доступ запрещен",
                emailNotFound: "Email не найден. Обратитесь к администратору для смены пароля или установки email."
            }
        }
    }
}

export default ru;